import { render, staticRenderFns } from "./TeamsMemberListEmptyWrapper.vue?vue&type=template&id=28656645&scoped=true"
var script = {}
import style0 from "./TeamsMemberListEmptyWrapper.vue?vue&type=style&index=0&id=28656645&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28656645",
  null
  
)

export default component.exports